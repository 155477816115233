import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38ae693e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card px-2 mt-4" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-title fw-bold text-dark" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "image-grid"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "overlay"
}
const _hoisted_11 = ["innerHTML"]

import { computed } from 'vue';


export default {
  props: {
  title: {
    type: String,
    default: "How To Import and Ship Products Internationally",
  },
  description: {
    type: String,
    default: "Whether you're importing products or selling to customers across borders, Shopify makes things simple.",
  },
  shortDescription: {
    type: String,
    default: "",
  },
  imageSrc: {
    type: Array,
    default: () => [{ image_url: "/path-to-your-default-image.png" }], // Replace with actual default images
  },
},
  setup(__props) {

const props = __props



// Limit display to 4 images for grid view
const displayedImages = computed(() => props.imageSrc.slice(0, 4));

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(__props.title), 1),
      _createElementVNode("p", {
        class: "text-muted",
        innerHTML: __props.shortDescription
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        (__props.imageSrc.length === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("img", {
                src: __props.imageSrc[0].image_url,
                class: "d-block img-fluid rounded",
                style: {"max-height":"30rem"},
                alt: "Product Image"
              }, null, 8, _hoisted_7)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(displayedImages), (image, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(["grid-item", { 'overlay-container': index === 3 && __props.imageSrc.length > 4 }])
                }, [
                  _createElementVNode("img", {
                    src: image.image_url,
                    class: "img-fluid rounded",
                    alt: "Product Image"
                  }, null, 8, _hoisted_9),
                  (index === 3 && __props.imageSrc.length > 4)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, " +" + _toDisplayString(__props.imageSrc.length - 4) + " More ", 1))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ]))
      ]),
      _createElementVNode("p", {
        class: "text-muted mt-3 pt-5",
        innerHTML: __props.description
      }, null, 8, _hoisted_11)
    ])
  ]))
}
}

}